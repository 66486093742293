
    jQuery('.part-search-input').on('input', function() {
        var query = jQuery(this).val();

        if (query.length > 2) {
            jQuery.ajax({
                url: ajax_part_search.ajaxurl,
                type: 'POST',
                data: {
                    action: 'part_search',
                    query: query
                },
                success: function(response) {
                    jQuery('.part-search-results').html(response);
                }
            });
        } else {
            jQuery('.part-search-results').empty();
        }
    });

    function addItemToShoppingCart(data) {
        console.log(data);
    
        let args = {
            action: 'add_product_to_cart',
        };
        if (data.product_sku) {
            // set args.product_sku
            args.product_sku = data.product_sku;
        } else if (data.productNumber) {
            // set args.product_number
            args.product_number = data.productNumber;
        } else {
            console.log('No product ID provided');
            return;
        }
    
        jQuery.ajax({
            url: ajax_part_search.ajaxurl,
            type: 'POST',
            data: args,
            success: function(response) {
                if (response.success) {
                    jQuery('#part-search-alert-message').text('Item added to order!');
                    jQuery('#part-search-alert').addClass('alert-success');
                    window.setTimeout(function() {
                        jQuery('#part-search-alert').removeClass('alert-success');
                    }, 5000);
                } else {
                    jQuery('#part-search-alert-message').text('We do not currently carry this item. We may be able to special order it, please contact us for more info.');
                    jQuery('#part-search-alert').addClass('alert-danger');
                    window.setTimeout(function() {
                        jQuery('#part-search-alert').removeClass('alert-danger');
                    }, 5000);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                jQuery('#part-search-alert-message').text('An error occurred. Please try again.');
                jQuery('#part-search-alert').addClass('alert-danger');
                window.setTimeout(function() {
                    jQuery('#part-search-alert').removeClass('alert-danger');
                }, 5000);
            }
        });
    }

    jQuery(document).on('click', '.add-to-cart', function(e) {
        e.preventDefault();

        var product_sku = jQuery(this).data('product_sku');

        let data = {
            product_sku: product_sku
        };

        addItemToShoppingCart(data);
        
    });